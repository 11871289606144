

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

body {
    /* Add the font-family property for the body explicitly */
    font-family: 'Poppins', sans-serif;
}

