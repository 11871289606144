.resouce {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }
  
  .r-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items horizontally */
  }
  
  .r-container .r-box {
    position: relative;
    width: 90%; /* Adjust width for responsiveness */
    max-width: 400px; /* Set a maximum width if needed */
    height: auto; /* Adjust height for responsiveness */
    margin: 10px; /* Add margin for spacing between boxes */
    overflow: hidden;
  }
  
  /* Add media query for smaller screens */
  @media (max-width: 768px) {
    .r-container .r-box {
      width: calc(100% - 40px);
    }
  }
  
  .r-container .r-box .img-box {
    position: relative;
    width: 100%;
    height: auto; /* Adjust height for responsiveness */
    overflow: hidden;
  }
  
  .r-container .r-box .img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
  }
  
  .r-container .r-box .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1.5s ease-in-out;
  }
  
  .r-container .r-box .r-contentbx {
    position: absolute;
    top: 50%; /* Center content vertically */
    left: 50%; /* Center content horizontally */
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .r-container .r-box .layer.layer1 {
    background: #f59e0b;
    transform: translateY(100%);
  }
  
  .r-container .r-box:hover .layer.layer1 {
    background: #f59e0b;
    transform: translateY(-100%);
  }
  