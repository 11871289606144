*{
    margin:0;
    padding:0;

    

}
body{
    height: 100vh;
    display: grid;
 
    background: #082f49
    ;
  
    background-repeat: no-repeat;
    background-size: cover;
    


}
table{
    width: 1000px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   
}
table,th,td{
    padding:8px;
 border: 1px solid #e5e7e9; 
    border-collapse: collapse;
    text-align: left;
    cursor: pointer;
}
td{
    font-size: 16px;
  
}
th{
    background-color: #082f49;
    color: white;


}
tr{
    background-color:#082f49 ;
    color: white;
}
tr:hover{
    background-color: #f59e0b;
    /* z-index: -1; */
    /* opacity: 0.7; */
       color: white;
       /* transform: scale(1.1); */
       transition: transform 300ms ease-in-out;
      
   }
/* tr:nth-child(odd){
    background-color: #0496C7;
   
}
tr:nth-child(odd):hover{
    background-color: #fed7aa;
    color: black;
    transform: scale(1.2);
    transition: transform 300ms ease-in-out;
   
}
tr:nth-child(even){
    background-color: #f59e0b;
   
}
tr:nth-child(even):hover{
    background-color: #0496C7;
    color: white;
    transform: scale(1.2);
    transition: transform 300ms ease-in-out;
   
} */