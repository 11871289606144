.service {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

.service-container {

  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transition: 0.5s;
}

.service-container .service-card {
  position: relative;
  width: 240px;
  height: 480px;
  background: rgb(238, 237, 237);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  margin: 2px 5px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.service-container:hover .service-card {
  filter: blur(5px);
  transform: scale(0.9);
}

.service-container .service-card:hover {
  filter: blur(0px);
  transform: scale(1);
}

.service-container .service-card::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f59e0b;
  z-index: 1;
  transition: 0.5s;
}

.service-container .service-card:hover::before {
  background: #0496C7;
  transform: scaleY(1.15);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.service-container .service-card .service-content {
  text-align: center;
  z-index: 2;
  padding: 0 20px;
  transition: 0.5s;
  color: white;
}

.service-container .service-card:hover .service-content {
  color: azure;
}

/* Media queries for responsiveness */
@media (max-width: 1240px) {
  .service-container .service-card {
    width: 240px;
    height: 480px;
    margin: 10px 2px;
  }
}

@media (max-width: 1220px) {
  .service-container .service-card {
    width: 250px;
    height: 480px;
    margin: 10px 2px;
  }
}
@media (max-width: 1216px) {
  .service-container .service-card {
    width: 300px;
    height: 480px;
    margin: 10px 2px;
  }
}





