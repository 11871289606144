.span{
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
.appear{
   animation:textAppear 2s ease-in-out ;
  
}
@keyframes textAppear{
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.text-reveal{
    animation: text-reveal 2s linear forwards;
}

@keyframes text-reveal {
    0% {
        transform: translate(0,20%);
      }
      100% {
        transform: translate(0,0);
      }
}