*
{
	padding:0;
	margin:0;
}
.c-container
{
	padding:30px;
	font-family:'Roboto';
	font-size:16px;
	font-weight:300;
	line-height:24px;
}
.c-container p
{
	margin-bottom:15px;
    color: #fff;
}

.appear{
	animation:textAppear 2s ease-in-out ;
   
 }
.thumbanail
{
	width:450px;
	position:relative;
	box-shadow:0 0 5px rgba(0,0,0,.4);
	background-color:#fff;
	overflow:hidden;
	border-radius:8px;
	margin-bottom:10px;
}
.thumbanail:before
{
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;

	z-index:2;
	overflow:hidden;
	box-sizing:border-box;
}
.thumbanail img
{
	width:100%;
	height:auto;
	border-radius:8px;
	transform:scale(1) ;
	transition:all .5s linear;
}
.thumbanail:hover img
{
	transform:scale(1.1) ;
}


.thumbanail.right
{
	float:right;
	margin-left:20px;
}
.thumbanail.left
{
	float:left;
	margin-right:20px;
}

@media screen and (max-width:767px)
{
	
	.thumbanail.right
	{
		float:none;
		margin-left:0px;
	}
	.thumbanail.left
	{
		float:none;
		margin-right:0px;
	}
	.thumbanail{
		width:100%;
	}
}
.readmmore{
	font-size: larger !important;
}
.text{
	font-size: larger;
}

.text-reveal{
    animation: text-reveal 2s linear forwards;
}

@keyframes text-reveal {
    0% {
        transform: translate(0,20%);
      }
      100% {
        transform: translate(0,0);
      }
}

