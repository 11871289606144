.box {
  min-height: 70vh;

  animation: animate 60s infinite ;
  background-repeat: no-repeat;
  background-size: cover; 
}

@keyframes animate{
  0%{
      
     background: url('../../../static/nature5.avif')
     ;
  background-position: center center;
     background-repeat: no-repeat;
     background-size: cover;
  }
  10%{

      background: url('../../../static/bg-logo2.png.jpg')
      ;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
  }
  20%{

      background: url('../../../static/sample1.webp')
      ;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
  }
  40%{

      background: url('../../../static/sample2.avif')
      ;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
  }
  60%{
      
background: url('../../../static/bglogo3.jpg')
;
background-repeat: no-repeat;
background-size: cover;
background-position: center center;
  }
  80%{
      
      background: url('../../../static/sample3.avif')
      ;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
          }
  100%{
      background: url('../../../static/nature5.avif');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
  }
}


.text-reveal{
  animation: 2s linear forwards;
}

.appear{
  animation:textAppear 2s ease-in-out ;
 
}
/* @keyframes textChange {
  0%, 49.9% {
    opacity: 1;
    content: 'IMZI Advisory';
  }
  50%, 100% {
    opacity: 1;
    content: 'Iman Hasan, Chartered Accountants (IHCA)';
  }
}

.animated-text::after {
  content: 'IMZI Advisory'; 
  animation: textChange 10s infinite ease-in-out; 
  display: inline-block;
} */
/* @keyframes fadeInOut {
  0%, 49.9% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}

.animated-text {
  animation: fadeInOut 15s infinite ease-in-out;
  transition: opacity 2s ease-in-out;
} */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-cyan h2 span {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.text-cyan h2 span:nth-child(1) { animation-delay: 0.1s; }
.text-cyan h2 span:nth-child(2) { animation-delay: 0.2s; }
.text-cyan h2 span:nth-child(3) { animation-delay: 0.3s; }
.text-cyan h2 span:nth-child(4) { animation-delay: 0.4s; }
